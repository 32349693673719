import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import PanelWithBord from './PanelWithBord'


export default function ReparaturMidsection2() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">

        <h2 className="mt-16 text-2xl font-semibold text-gray-900">
            Was Ihnen entgehen könnte, wenn Sie sich nicht für uns entscheiden:
          </h2>
          <ul className="mt-8 space-y-4">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span><strong>Komfortabel:</strong> Kein technischer Stress – lehnen Sie sich zurück, während wir alles einrichten.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span><strong>Zeitersparnis:</strong> Sofort startklar ohne lange Einarbeitung oder Fehlern.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span><strong>Expertenwissen:</strong> Unsere Spezialisten kennen die neuesten Geräte und Betriebssysteme.</span>
            </li>
          </ul>
    </div>
    </div>
  )
}