 import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import PanelWithBord from './PanelWithBord'


export default function HandyService() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
        Unser Service auf einen Blick:
          </h1>
        
        <div className="mt-10 max-w-2xl">
          <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span>
                <strong className="font-semibold text-gray-900">Ersteinrichtung:</strong> Wir richten Ihr 
                neues Smartphone oder Tablet professionell ein, von der Erstaktivierung bis zur 
                Softwarekonfiguration.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span>
                <strong className="font-semibold text-gray-900">Apps & Konten:</strong> Einrichtung 
                Ihrer wichtigsten Apps und bis zu drei E-Mail- oder Nutzerkonten.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span>
                <strong className="font-semibold text-gray-900">Datenübertragung:</strong> Sicherer 
                Transfer von Kontakten, Fotos, Videos und mehr von Ihrem alten Gerät.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span>
                <strong className="font-semibold text-gray-900">Sicherheit:</strong> Einrichtung von 
                Passwörtern, Fingerabdruck- und Gesichtserkennung.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span>
                <strong className="font-semibold text-gray-900">Persönliche Einführung:</strong> Wir 
                zeigen Ihnen die wichtigsten Funktionen und geben Tipps für die optimale Nutzung.
              </span>
            </li>
          </ul>

          <h2 className="mt-16 text-2xl font-semibold text-gray-900">
            Was Ihnen entgehen könnte, wenn Sie sich nicht für uns entscheiden:
          </h2>
          <ul className="mt-8 space-y-4">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span><strong>Komfortabel:</strong> Kein technischer Stress – lehnen Sie sich zurück, während wir alles einrichten.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span><strong>Zeitersparnis:</strong> Sofort startklar ohne lange Einarbeitung oder Fehlern.</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
              <span><strong>Expertenwissen:</strong> Unsere Spezialisten kennen die neuesten Geräte und Betriebssysteme.</span>
            </li>
          </ul>

       
        </div>
      </div>
    </div>
  )
}