import React from 'react';
//import HeroSectionAboutUs from '../components/HeroSectionAboutUs';
import Reparatur  from '../components/reparatur';
import ReparaturMidsection from '../components/ReparaturMidsection';
import PanelWithBord from '../components/PanelWithBord';
import CenteredDark from '../components/FAQs/CenteredDark';

import LandingPage from '../components/LandingPageGerate';

function Heimnetwerke() {
    return (
        <div>
            <LandingPage />
        </div>
    );
}

export default Heimnetwerke;
