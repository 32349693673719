import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
//import ContactUs from "./components/ContactUs/ContactUs";
import ContactUs from "./pages/ContactUs";
import Services from "./components/Service/Services";
import Service from "./pages/Service";
import PageNotFound from "./components/PageNotFound";
import Blog from "./components/Blog/Blog";
import Faqs from "./components/FAQs/Faqs";
import Datenschutz from "./components/Datenschutz";
import Impressum from "./components/Impressum";
import JobSearch from "./components/JobSearch";
import Handy from "./pages/Handy";
import Heimnetwerke from "./pages/Heimnetwerk";
import Gerate from "./pages/Gerate";
import Betriebssystem from "./pages/Betriebssystem";




function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/service" element={<Service />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/Faq" element={<Faqs />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/job" element={<JobSearch />} />
        <Route path="/reparatur" element={< Handy />} />
        <Route path="/Heimnetzwerk" element={< Heimnetwerke />} />
        <Route path="/Gerate" element={< Gerate />} />
        <Route path="/Betriebssystem" element={< Betriebssystem />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
